<template>
  <v-container fluid>
    <Editor :initial="dataItem" :pageMode="pageMode" :pageTitle="pageTitle" @data="save" />
  </v-container>
</template>
<script>
import Editor from "../_components/editor.vue";
export default {
  components: {
    Editor,
  },
  data() {
    return {
      dataItem: {},
      pageMode: "create",
      pageTitle: "Create Tier",
    };
  },
  methods: {
    save(data) {
      //logic comes here
      const url = "/tiers";
      const self = this;
      self.loader = true;
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          self.loader = false;
          console.log(res, "r");
          // self.$refs.snackbar.show("Item group created", "green");
          self.$router.push("/channels/tiers");
        })
        .catch((err) => {
          console.log(err, "err");
          // this.$refs.snackbar.show(err, "red");
        });
    },
  },
};
</script>